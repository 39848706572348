<template>
  <section class="section-hero">
    <div class="container-fluid">
      <div class="row align-items-center h-100">
        <div class="col-lg-9 mx-auto mx-xl-0 text-center text-lg-left">
          <h1 class="section-title">
            The easiest way to order wheelchair-accessible rides
          </h1>
          <p class="section-hero-text">
            Getting a wheelchair-accessible ride should be just as easy as
            getting one that is not.
            <span class="text-nowrap">CareRide Technologies</span> makes it
            possible by uniting certified vendors and patients for more freedom
            and mobility of the latter.
          </p>
          <router-link to="/ride" class="btn btn-primary btn-mw-220"
            >Book a ride</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
