// Feedbacks
export default [
  {
    id: 1,
    title: "It saves so much time and effort.",
    text: "Before CareRide, I had to call a couple of different firms to secure the ride for my patients, and now the platform does the legwork for me. I know that I'm being connected to the nearest driver and I know the price I pay is fair. I know that I'm being connected to the nearest driver and I know the price I pay is fair.",
    author: "John Rickson",
    type: "client",
  },
  {
    id: 2,
    title: "My social life improved!",
    text: "It is so simple to use, wow. In the past, I would have to think twice when deciding whether to go someplace or stay home and now I go every time I need or want to. My social life improved. I just wished I learned about CareRide sooner.",
    author: "Ann Denvers",
    type: "client",
  },
  {
    id: 3,
    title: "It's a game changer for me.",
    text: "What I like most about CareRide is that I no longer have to contact multiple companies to check on availability and price. I go to the platform and I'm being matched to the nearest available driver and see the price.",
    author: "Jack Nicolsen",
    type: "client",
  },
  {
    id: 4,
    title: "I'm giving more rides and making more.",
    text: "I've been working as a certified driver for a couple of years now, and partnering with CareRide is one of the biggest milestones for me. Now I have access to more potential clients, and my working hours got busier, which is a good thing as my income depends on how many rides I give.",
    author: "Joe Tribiani",
    type: "client",
  },
];
