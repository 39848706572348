// Whatis
export default [
  {
    id: 1,
    title: "Let us know about your route",
    text: "Indicate where you are, what's your destination, and when you need a ride - can be a same-day trip or the one you book in advance. Don't forget you can book both for yourself or for someone you care about.",
    pic: require("@/assets/images/landing/whatis-screens/1.jpeg"),
  },
  {
    id: 2,
    title: "Pick a company to give you a ride",
    text: "The best things about CareRide is that you no longer have to contact different companies to check on availability and prices. Take a look at what's available around you and choose based on what pricing and timing works best.",
    pic: require("@/assets/images/landing/whatis-screens/2.png"),
  },
  {
    id: 3,
    title: "Wait for the car and get a ride",
    text: "Once the ride is booked, the rest is on us. You can be sure that wherever you (or your loved ones) are heading, they are going to get there comfortably, safely, and on time.",
    pic: require("@/assets/images/landing/whatis-screens/3.png"),
  },
];
