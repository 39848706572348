<template>
  <section class="section-nemt">
    <div class="container-fluid">
      <h1 class="section-title text-center text-lg-left">
        What is <i>NEMT</i> ?
      </h1>
      <div class="row">
        <div class="col-lg-6">
          <p class="section-text">
            <b>Non-emergency medical transportation (NEMT)</b> is a transportation
            service offered to those who face challenges and barriers getting to
            and from their medical appointments.
          </p>
          <p class="section-text">
            NEMT allows people with mobility challenges to be able to access
            healthcare safely and on time. While the term is mostly used in
            relation to commutes to and from medical appointments, it may cover
            other areas like grocery shopping, outing, going to church, etc.
          </p>
          <p class="section-text section-text-bold">
            As the NEMT industry is expected to grow by 4% year-to-year,
            CareRide Technologies is focused on making wheelchair-accessible rides
            comfortable and easy to order.
          </p>
        </div>
        <div class="col-lg-6 text-center text-lg-left">
          <img
            :src="require('@/assets/images/landing/nemt.jpg')"
            alt=""
            class="img-fluid mt-4 mt-lg-0"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
