<template>
  <section class="section-ride">
    <div class="container-fluid">
      <div class="row align-items-center h-100">
        <div class="col-lg-8 col-xl-9 mx-auto mx-xl-0 text-center text-lg-left">
          <h1 class="section-title">Got plans? We'll give you a ride.</h1>
          <router-link to="/ride" class="btn btn-primary btn-mw-220"
            >Book a ride</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
